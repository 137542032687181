<template>
  <b-modal
    title="Banner Fixo"
    :visible="visible"
    size="lg"
    hide-footer
    @hide="close"
  >
    <b-form @submit="uploadBanner">
      <form-file
        v-model="bannerPrimary"
        label="Banner principal"
        placeholder="Selecione uma imagem (800x800)"
        icon="card-image"
        accept="png, jpeg, jpg"
        required
      />

      <form-file
        v-model="bannerSecondary"
        label="Banner secundário"
        placeholder="Selecione uma imagem (800x800)"
        icon="card-image"
        accept="png, jpeg, jpg"
      />
      <div class="d-flex justify-content-end">
        <b-button type="submit" variant="primary">SALVAR</b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { mapMutations } from "vuex";
import http from "../../plugins/http";
import FormFile from "../form/FormFile.vue";

export default {
  components: { FormFile },
  name: "FixedBanner",
  model: {
    prop: "visible",
    event: "close",
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      bannerPrimary: null,
      bannerSecondary: null,
    };
  },
  methods: {
    ...mapMutations(["setToastedSuccess", "setToastedError"]),

    close() {
      this.$emit("close", false);
    },
    async uploadBanner(event) {
      event.preventDefault();
      try {
        const formData = new FormData();

        formData.append("primary", this.bannerPrimary);
        formData.append("secondary", this.bannerSecondary);

        await http.post("/banner/fixed", formData);
        this.bannerPrimary = null;
        this.bannerSecondary = null;
        this.setToastedSuccess({ message: "Banner enviado com sucesso" });
      } catch (err) {
        this.setToastedError({ message: "Erro ao enviar o banner" });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
