<template>
  <b-modal
    title="Parâmetros"
    :visible="visible"
    size="lg"
    hide-footer
    @hide="close"
  >
    <b-form @submit.prevent="updateParameters">
      <b-form-group>
        <b-form-checkbox v-model="parameter.exibir_banners">
          Exibir Banner Carrossel
        </b-form-checkbox>
        <b-form-checkbox v-model="parameter.exibir_banner_secundario">
          Exibir Banner Fixo
        </b-form-checkbox>
        <b-form-checkbox v-model="parameter.exibir_ofertas">
          Exibir Ofertas
        </b-form-checkbox>
        <b-form-checkbox v-model="parameter.exibir_destaques">
          Exibir Destaques
        </b-form-checkbox>
        <b-form-checkbox v-model="parameter.exibir_lancamentos">
          Exibir Lançamentos
        </b-form-checkbox>
        <b-form-checkbox v-model="parameter.exibir_pesquisa">
          Permitir Pesquisas
        </b-form-checkbox>
        <b-form-checkbox v-model="parameter.exibir_scanerBarCode">
          Permitir Pesquisa por Código de Barras
        </b-form-checkbox>
        <b-form-checkbox v-model="parameter.exibir_preco">
          Exibir Preço do Produto
        </b-form-checkbox>
        <b-form-checkbox v-model="parameter.exibir_embalagem">
          Exibir Embalagem
        </b-form-checkbox>
        <b-form-checkbox v-model="parameter.exibir_desconto_cliente">
          Exibir Desconto do Cliente
        </b-form-checkbox>
        <b-form-checkbox v-model="parameter.exibir_marcas">
          Exibir Marcas
        </b-form-checkbox>
        <b-form-checkbox v-model="parameter.exibir_compartilhar">
          Permitir Compartilhamento do Produto
        </b-form-checkbox>
        <b-form-checkbox v-model="parameter.exibir_atendimento">
          Permitir Atendimento
        </b-form-checkbox>
        <b-form-checkbox v-model="parameter.exibir_ligamos_para_voce">
          Permitir Ligamos Para Você
        </b-form-checkbox>
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-button type="submit" variant="primary">SALVAR</b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { mapMutations } from "vuex";
import http from "../../plugins/http";

export default {
  name: "ParametersForm",
  model: {
    prop: "visible",
    event: "close",
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      parameter: {},
    };
  },
  methods: {
    ...mapMutations(["setToastedError", "setToastedSuccess"]),

    close() {
      this.$emit("close", false);
    },
    async updateParameters() {
      try {
        const { data: parameter } = await http.put(
          "/parameter",
          this.parameter
        );
        this.parameter = parameter;
        this.setToastedSuccess({
          message: "Parâmetros atualizados com sucesso",
        });
      } catch (err) {
        this.setToastedError({
          message: "Não foi possível atualizar os parâmetros",
        });
      }
    },
  },
  async created() {
    try {
      const { data: parameter } = await http.get("/parameter");
      this.parameter = parameter;
    } catch (err) {
      console.log(err);
    }
  },
};
</script>

<style lang="scss" scoped></style>
