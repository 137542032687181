<template>
  <b-modal
    title="Logs de Sincronia"
    :visible="visible"
    size="xl"
    hide-footer
    @hide="close"
  >
    <b-table
      :items="formattedLogs"
      :fields="fields"
      :busy="isLoading"
      :style="{ borderTop: 'hidden' }"
    />
    <div class="d-flex justify-content-center">
      <b-button @click="loadMore">Carregar Mais</b-button>
    </div>
  </b-modal>
</template>

<script>
import http from "../../plugins/http";
export default {
  name: "SyncLog",
  model: {
    prop: "visible",
    event: "close",
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      page: 2,
      logs: [],
      fields: [
        {
          key: "log",
          label: "Descrição",
        },
        {
          key: "created_at",
          label: "Data / Hora",
        },
      ],
    };
  },
  computed: {
    formattedLogs() {
      return this.logs.map((e) => ({
        ...e,
        created_at: new Date(e.created_at).toLocaleString("pt-BR"),
      }));
    },
  },
  methods: {
    close() {
      this.$emit("close", false);
      this.initialFetch();
    },

    async initialFetch() {
      this.isLoading = true;
      try {
        const { data } = await http.get("/log/sync");
        this.logs = data;
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    },
    async loadMore() {
      this.isLoading = true;
      try {
        const { data } = await http.get(`/log/sync?page=${this.page}`);
        this.logs.push(...data);
        this.page++;
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    },
  },
  async created() {
    await this.initialFetch();
  },
};
</script>

<style lang="scss" scoped></style>
