<template>
  <b-modal
    title="Banner Carrossel"
    :visible="visible"
    size="huge"
    hide-footer
    @hide="close"
  >
    <b-form @submit.prevent="saveBanner">
      <form-input
        v-model="form.descricao"
        label="Descrição:"
        placeholder="Informe uma descrição"
        icon="card-text"
        required
      />

      <b-form-row>
        <b-col>
          <form-file
            v-model="form.site"
            label="Banner do site:"
            placeholder="Selecione uma imagem para web (1920x500)"
            icon="card-image"
            accept="png, jpeg, jpg"
            required
          />
        </b-col>
        <b-col>
          <form-file
            v-model="form.mobile"
            label="Banner do app:"
            placeholder="Selecione uma imagem para o app (825x460)"
            icon="card-image"
            accept="png, jpeg, jpg"
            required
          />
        </b-col>
      </b-form-row>

      <div class="d-flex justify-content-end mb-3">
        <b-button type="submit" variant="primary">SALVAR</b-button>
      </div>

      <b-table :items="filterBanner" :fields="fields" bordered striped hover>
        <template #cell(actions)="row">
          <b-button
            :variant="row.item.ativo ? 'success' : 'danger'"
            size="sm"
            class="mr-2"
            @click="openToggleModal(row.item)"
          >
            <b-icon icon="eye" />
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            class="mr-2"
            @click="openRemoveModal(row.item)"
          >
            <b-icon-trash />
          </b-button>
        </template>
      </b-table>
    </b-form>

    <custom-modal
      v-model="showToggleModal"
      :message="toggleModalMessage"
      title="Tem certeza?"
      @confirm="toggleShowState"
    />

    <custom-modal
      v-model="showRemoveModal"
      :message="removeModalMessage"
      title="Tem certeza?"
      @confirm="removeShowState"
    />
  </b-modal>
</template>

<script>
import { mapMutations } from "vuex";
import http from "../../plugins/http";
import FormFile from "../form/FormFile.vue";
import FormInput from "../form/FormInput.vue";
import CustomModal from "../shared/CustomModal.vue";

export default {
  components: { FormFile, FormInput, CustomModal },
  name: "BannerCarousel",
  model: {
    prop: "visible",
    event: "close",
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showToggleModal: false,
      toggleModalMessage: "",
      showRemoveModal: false,
      removeModalMessage: "",
      form: {
        descricao: "",
        link_web: "",
        link_mobile: "",
        ativo: false,
      },
      banners: [],
      fields: [
        {
          key: "descricao",
          label: "Descrição",
        },
        {
          key: "link_web",
          label: "URL Web",
          sortable: true,
        },
        {
          key: "link_mobile",
          label: "URL App",
        },
        {
          key: "actions",
          label: "",
          thStyle: {
            width: "195px !important",
          },
        },
      ],
    };
  },
  async created() {
    try {
      const { data: banners } = await http.get("/banner");
      this.banners = banners;
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    ...mapMutations(["setToastedSuccess", "setToastedError"]),

    resetForm() {
      this.form = {
        descricao: "",
        link_web: "",
        link_mobile: "",
        ativo: false,
      };
    },

    close() {
      this.$emit("close", false);
    },
    openToggleModal({ ativo, id }) {
      this.showToggleModal = true;

      if (ativo) {
        this.toggleModalMessage = "Deseja deixar de exibir o banner";
      } else {
        this.toggleModalMessage = "Deseja reexibir o banner";
      }

      this.bannerSelected = id;
    },
    openRemoveModal({ id, descricao }) {
      this.showRemoveModal = true;
      this.removeModalMessage = `Deseja remover o banner: ${descricao}`;
      this.bannerSelected = id;
    },
    async saveBanner() {
      try {
        const formData = new FormData();

        for (const key of Object.keys(this.form)) {
          formData.set(key, this.form[key]);
        }

        const { data } = await http.post("/banner", formData);
        this.banners.push(data);
        this.resetForm();
        this.setToastedSuccess({ message: "Banner criado com sucesso" });
      } catch (err) {
        this.setToastedError({ message: "Erro ao criar o banner" });
      }
    },
    async toggleShowState() {
      try {
        const id = this.bannerSelected;
        const { data: updatedBanner } = await http.patch(`/banner/${id}`);

        const index = this.banners.findIndex((banner) => banner.id === id);
        this.banners.splice(index, 1, updatedBanner);

        this.setToastedSuccess({ message: "Operação realizada com sucesso" });
      } catch (err) {
        this.setToastedError({ message: "Erro ao realizar operação" });
      }
      this.showToggleModal = false;
    },
    async removeShowState() {
      try {
        const id = this.bannerSelected;
        await http.delete(`/banner/${id}`);

        this.banners = this.banners.filter((banner) => banner.id !== id);
        this.setToastedSuccess({ message: "Operação realizada com sucesso" });
      } catch (err) {
        this.setToastedError({ message: "Erro ao realizar operação" });
      }

      this.showRemoveModal = false;
    },
    lastOccurrenceString(string = "") {
      const before = string.lastIndexOf("/");
      const url = string.slice(before + 1, string.length);
      return url;
    },
  },
  computed: {
    filterBanner() {
      return this.banners.map((e) => ({
        ...e,
        link_web: this.lastOccurrenceString(e.link_web),
        link_mobile: this.lastOccurrenceString(e.link_mobile),
      }));
    },
  },
};
</script>

<style lang="scss" scoped></style>
