<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-button class="m-3" variant="primary" @click="showBannersModal">
          <div class="d-flex flex-column">
            <div style="font-size: 150px">
              <b-icon icon="images" />
            </div>
            <span class="my-5">Banner Carrousel</span>
          </div>
        </b-button>
        <b-button class="m-3" variant="primary" @click="showFixedBannerModal">
          <div class="d-flex flex-column">
            <div style="font-size: 150px">
              <b-icon icon="card-image" />
            </div>
            <span class="my-5">Banner Fixo</span>
          </div>
        </b-button>
        <b-button class="m-3" variant="primary" @click="showLogSearchModal">
          <div class="d-flex flex-column">
            <div style="font-size: 150px">
              <b-icon icon="journal-text" />
            </div>
            <span class="my-5">Logs de Pesquisa</span>
          </div>
        </b-button>
        <b-button class="m-3" variant="primary" @click="showSyncModal">
          <div class="d-flex flex-column">
            <div style="font-size: 150px">
              <b-icon icon="file-earmark-text" />
            </div>
            <span class="my-5">Logs de Sincronização</span>
          </div>
        </b-button>
        <b-button class="m-3" variant="primary" @click="showAccessModal">
          <div class="d-flex flex-column">
            <div style="font-size: 150px">
              <b-icon icon="person-lines-fill" />
            </div>
            <span class="my-5">Logs de Acesso</span>
          </div>
        </b-button>
        <b-button class="m-3" variant="primary" @click="showParameterModal">
          <div class="d-flex flex-column">
            <div style="font-size: 150px">
              <b-icon icon="toggles" />
            </div>
            <span class="my-5">Parâmetros</span>
          </div>
        </b-button>
      </b-col>
    </b-row>
    <banner-carousel v-model="bannersVisible" />
    <fixed-banner v-model="bannerVisible" />
    <search-log v-model="logSearchVisible" />
    <sync-log v-model="logSyncVisible" />
    <parameters-form v-model="parametersVisible" />
    <access-modal v-model="accessVisible" />
  </b-container>
</template>

<script>
import { mapMutations } from "vuex";
import AccessModal from "../components/config/AccessModal.vue";
import BannerCarousel from "../components/config/BannerCarousel.vue";
import FixedBanner from "../components/config/FixedBanner.vue";
import ParametersForm from "../components/config/ParametersForm.vue";
import SearchLog from "../components/config/SearchLog.vue";
import SyncLog from "../components/config/SyncLog.vue";

export default {
  components: {
    ParametersForm,
    SearchLog,
    SyncLog,
    FixedBanner,
    BannerCarousel,
    AccessModal,
  },
  name: "operations",
  data() {
    return {
      bannersVisible: false,
      bannerVisible: false,
      logSearchVisible: false,
      logSyncVisible: false,
      parametersVisible: false,
      accessVisible: false,
    };
  },
  methods: {
    ...mapMutations(["setToasted"]),

    showBannersModal() {
      this.bannersVisible = true;
    },
    showFixedBannerModal() {
      this.bannerVisible = true;
    },
    showLogSearchModal() {
      this.logSearchVisible = true;
    },
    showSyncModal() {
      this.logSyncVisible = true;
    },
    showParameterModal() {
      this.parametersVisible = true;
    },
    showAccessModal() {
      this.accessVisible = true;
    },
  },
};
</script>

<style></style>
