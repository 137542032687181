<template>
  <b-modal
    title="Parâmetros"
    :visible="visible"
    size="xl"
    hide-footer
    @hide="close"
  >
    <b-table :fields="fields" :items="logs">
      <template #cell(actions)="row">
        <b-button
          variant="primary"
          size="sm"
          class="mr-2"
          @click="getLogInfo(row.item, row.toggleDetails)"
        >
          Mais informações
        </b-button>
      </template>

      <template #cell(created_at)="row">
        {{ new Date(row.item.created_at).toLocaleString() }}
      </template>

      <template #row-details="row">
        <b-card>
          <b-row>
            <b-col>
              <b>IP:</b>
              {{ row.item.info.ip }}
            </b-col>

            <b-col>
              <b>Pais:</b>
              {{ row.item.info.country }} - {{ row.item.info.countryCode }}
            </b-col>

            <b-col>
              <b>Estado:</b>
              {{ row.item.info.regionName }} - {{ row.item.info.region }}
            </b-col>

            <b-col>
              <b>Cidade:</b>
              {{ row.item.info.city }}
            </b-col>

            <b-col>
              <b>CEP:</b>
              {{ row.item.info.zip }}
            </b-col>
          </b-row>
        </b-card>
      </template>
    </b-table>
    <div class="d-flex justify-content-center">
      <b-button @click="loadMore">CARREGAR MAIS</b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapMutations } from "vuex";
import http from "../../plugins/http";

export default {
  name: "AccessModal",
  model: {
    prop: "visible",
    event: "close",
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      page: 2,
      logs: [],
      fields: [
        {
          key: "platform",
          label: "Plataforma",
          sortable: true,
        },
        {
          key: "ip",
          label: "IP",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Data acesso",
          sortable: true,
        },
        {
          key: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    ...mapMutations(["setToastedError", "setToastedSuccess"]),

    async loadMore() {
      try {
        const { data: logs } = await http.get(`/log/access?page=${this.page}`);
        this.page++;
        this.logs = [...this.logs, ...logs];
      } catch (err) {
        console.log(err);
      }
    },
    async getLogInfo(log) {
      try {
        let foundIndex = this.logs.findIndex((e) => e.id === log.id);

        if (!log._showDetails) {
          const { data: info } = await http.get(`/log/access/${log.id}`);

          const newLog = {
            ...log,
            _showDetails: true,
            info,
          };

          this.logs.splice(foundIndex, 1, newLog);
        } else {
          const newLog = {
            ...log,
            _showDetails: false,
          };

          this.logs.splice(foundIndex, 1, newLog);
        }
      } catch (err) {
        console.log(err);
      }
    },
    close() {
      this.$emit("close", false);
    },
  },
  async created() {
    try {
      const { data: logs } = await http.get("/log/access");
      this.logs = logs;
    } catch (err) {
      console.log(err);
    }
  },
};
</script>

<style lang="scss" scoped></style>
